/*
 * Copyright 2018 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Action } from 'redux';

export interface LatLng {
  readonly lat: number;
  readonly lng: number;
}

export interface LatLngWithDescription extends LatLng {
  description: string;
}

export interface Location extends LatLng {
  readonly id: number;
  // TODO decide between optional, nullable and more complex structure (displayName, fullDescription, address, ...)
  readonly name?: string;
  readonly street?: string;
  readonly city?: string;
  readonly state?: string;
  readonly zip?: string;
  readonly phone?: string;
  readonly pti_id?: string;
  readonly window_start?: number;
  readonly window_end?: number;
}

export interface Vehicle extends LatLng {
  readonly id: number;
  readonly name: string;
  readonly street: string;
  readonly city?: string;
  readonly state?: string;
  readonly zip?: string;
  readonly phone?: string;
  starttime: number;
  capacity: number;
  marker_color: number;
  service_time: number;
  zipcode_list: string;
}

export interface Problem extends Location {
  isDriver: boolean;
  starttime: number;
  capacity: number;
}

export interface Route {
  readonly vehicle: Vehicle; // TODO change to vehicleId
  readonly visits: Location[];
  readonly arrivalTimes: number[];
  readonly distance: string;
}

export type LatLngTuple = [number, number];

export interface RouteWithTrack extends Route {
   track: LatLngTuple[];
}

export interface Plan {
  readonly sessionID: string;
  readonly isLoading: boolean;
  readonly isSolving: boolean;
  readonly isCleaning: boolean;
  readonly useWindows: boolean;
  readonly hasRoutes: boolean;
  readonly distance: string;
  readonly vehicles: Vehicle[];
  readonly visits: Location[];
  readonly routes: RouteWithTrack[];
  readonly problems: Problem[];
  readonly planID: string;
  readonly windowCount: string;
}

export enum ActionType {
  UPDATE_ROUTING_PLAN = 'UPDATE_ROUTING_PLAN',
  MOVE_LOCATIONS = 'MOVE_LOCATIONS',
  ADD_DRIVER = 'ADD_DRIVER',
  EDIT_DRIVERS = 'EDIT_DRIVERS',
  UPDATE_DRIVERS = 'UPDATE_DRIVERS',
  DISCARD_PROBLEM = 'DISCARD_PROBLEM',
  DELETE_LOCATION = 'DELETE_LOCATION',
  DELETE_VEHICLE = 'DELETE_VEHICLE',
  CLEAR_SOLUTION = 'CLEAR_SOLUTION',
  SWAP_DRIVERS = 'SWAP_DRIVERS',
  START_SOLVE = 'START_SOLVE',
  START_CLEAN = 'START_CLEAN',
  STOP_SOLVE = 'STOP_SOLVE',
  TOGGLE_USE_WINDOWS = 'TOGGLE_USE_WINDOWS',
  SET_SESSIONID = 'SET_SESSIONID',
}

export interface ClearRouteAction extends Action<ActionType.CLEAR_SOLUTION> {
}

export interface SwapDriversAction extends Action<ActionType.SWAP_DRIVERS> {
  readonly value: string;
}

export interface StartSolveAction extends Action<ActionType.START_SOLVE> {
}

export interface StartCleanAction extends Action<ActionType.START_CLEAN> {
}

export interface StopSolveAction extends Action<ActionType.STOP_SOLVE> {
}

export interface ToggleUseWindowsAction extends Action<ActionType.TOGGLE_USE_WINDOWS> {
}

export interface MoveLocationsAction extends Action<ActionType.MOVE_LOCATIONS> {
  readonly value: string;
}

export interface AddDriverAction extends Action<ActionType.ADD_DRIVER> {
  readonly value: string;
}

export interface EditDriversAction extends Action<ActionType.EDIT_DRIVERS> {
  readonly value: string;
}

export interface UpdateDriversAction extends Action<ActionType.UPDATE_DRIVERS> {
  readonly value: string;
}

export interface DiscardProblemAction extends Action<ActionType.DISCARD_PROBLEM> {
  readonly value: number;
}

export interface DeleteLocationAction extends Action<ActionType.DELETE_LOCATION> {
  readonly value: number;
}

export interface VehicleCapacity {
  vehicleId: number;
  capacity: number;
}

export interface UpdateRouteAction extends Action<ActionType.UPDATE_ROUTING_PLAN> {
  readonly plan: Plan;
}

export interface SetSessionIDAction extends Action<ActionType.SET_SESSIONID> {
  readonly sessionID: string;
}

export type PlanAction =
  | DeleteLocationAction
  | MoveLocationsAction
  | UpdateRouteAction
  | ClearRouteAction
  | SwapDriversAction
  | StartSolveAction
  | StartCleanAction
  | StopSolveAction
  | SetSessionIDAction
  | ToggleUseWindowsAction;
