import { faHomeUser, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { Form, ListGroup, Modal, Spinner, Stack} from 'react-bootstrap';
import { backendUrl } from 'common';
import Button from 'react-bootstrap/Button';

export interface Props {
  sessionID: string;
  refreshHandler: any;
  handleClose: any;
}
var test = {
    id: 1,
    selected: false
}

const AddressChanges = (props: Props) => {
  const [isSearching, setIsSearching] = useState(true);
  const [isWorking, setIsWorking] = useState(false);
  const [jsonString, setJsonString] = useState([test]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setIsSearching(true);
    setIsWorking(false);
    setJsonString([]);
    getAddresses();
  }, []);

  function getAddresses() {
      fetch(`${backendUrl}/api/load/addresschanges/${props.sessionID}`, {
        method: 'POST',
      }).then(response => response.json())
        .then(data => {setJsonString(data); setIsSearching(false)})
        .catch(error => setIsSearching(false));
  }

  function selectHandler(i: number) {
    jsonString[i].selected = !jsonString[i].selected;
    let n = jsonString.filter(x => x.selected == true).length;
    setCount(n);
    setJsonString([...jsonString]);
  }

  function handleSubmit() {
    if (count === 0) {
        props.handleClose(); 
        return;
    }
    setIsWorking(true);
        fetch(`${backendUrl}/api/load/changeaddresses`, {
            method: 'POST', headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json' }, body: JSON.stringify( {
              sessionID: props.sessionID,
              ids: jsonString.filter(x => x.selected == true).map(x => x.id).join(',')
            })
          }).then(response => {props.refreshHandler(); props.handleClose()})
  }

  return (
    <>
     <Modal show={true} onHide={props.handleClose} scrollable={true} dialogClassName={"AddressEditorModal"} centered >
        <Modal.Header closeButton>
        <Modal.Title style={{color:'#0D6EFD'}}><FontAwesomeIcon icon={faHomeUser} style={{color: '#1A8754', fontSize:'22px'}} /> Select Addresses to Update</Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <ListGroup as="ul">
        <ListGroup.Item as="li" className="d-flex align-items-start" variant="secondary">
        <div style={{border:'1px', width:'30px'}}><FontAwesomeIcon icon={faSquareCheck} /></div>
        <div className="fw-bold ms-1" style={{width:'300px'}}>PTI Address</div>
        <div className="fw-bold ms-1" style={{width:'300px'}}>Current Router Address</div>
        </ListGroup.Item>
        {jsonString.map((x, i) => <AddressItem id={i} key={i} json={jsonString[i]} selectHandler={selectHandler} />)}
        
        </ListGroup>
        {jsonString.length===0 && isSearching?<><br />Searching...</>:null}
        {jsonString.length===0 && !isSearching?<><br />None Found</>:null}
        </Modal.Body>
        <Modal.Footer>
          <div>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button variant="primary" className={!isWorking ? 'd-none' : ''}>
          <Spinner animation="border" size="sm" />&nbsp;Update [{count}]
          </Button>
          <Button variant="primary" onClick={handleSubmit} className={isWorking ? 'd-none' : ''}>
          Update [{count}]
          </Button>
          </div>
        </Modal.Footer>
    </Modal>
    </>
  );

}

export interface AProps {
    id: number,
    json: any;
    selectHandler: (id: number) => void;
  }

const AddressItem: React.FC<AProps> = (props) => (
    <ListGroup.Item as="li" className="d-flex align-items-start">
    <Form.Check aria-label="option 1" checked={props.json.selected} onClick={(e)=>{props.selectHandler(props.id)}} />
    <div className="ms-3" style={{width:'300px'}}>
      <div className="fw-bold">{props.json.new_name}</div>
      <div>{props.json.new_street}</div>
      <div>{props.json.new_city}, {props.json.new_state} {props.json.new_zip}</div>
    </div>
    <div className="ms-3" style={{width:'300px'}}>
      <div className="fw-bold">{props.json.current_name}</div>
      <div>{props.json.current_street}</div>
      <div>{props.json.current_city}, {props.json.current_state} {props.json.current_zip}</div>
    </div>
    </ListGroup.Item>
  );

export default AddressChanges;