/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { clientStateOperations } from '.';
import { ThunkCommandFactory } from '../types';
import * as actions from './actions';
import { routeOperations } from '../plan';
import { UpdateRouteAction } from '../plan/types';
import { LoadFileAction, LoadDriversFileAction, FinishLoadingAction, RestorePlanAction, LeavePageAction, RefreshPlanAction, ShowErrorMessageAction } from './types';

export const finishLoading: ThunkCommandFactory<void, FinishLoadingAction> = (
  () => (dispatch, getState, client) => {
    dispatch(actions.finishLoading());
  });

  export const showErrorMessage: ThunkCommandFactory<string, ShowErrorMessageAction> = (
    (message) => (dispatch, getState, client) => {
      dispatch(actions.showErrorMessageAction(message));
    });

export const loadFile: ThunkCommandFactory<string, LoadFileAction> =
  (fileData) => (dispatch, getState, client) => {
    
    dispatch(actions.loadClients(fileData));
    client.loadClients(getState().plan.sessionID, fileData).then(response => response.text()).then(data => {
      if (data==='SESSION TIMEOUT') {localStorage.setItem('vehicle_router_status', 'timedout'); window.location.reload();}
      else {
        setTimeout(() => { dispatch(clientStateOperations.refreshPlan()); }, 3000);
      }
    });
  };

  export const loadDriversFile: ThunkCommandFactory<string, LoadDriversFileAction> =
  (fileData) => (dispatch, getState, client) => {
    dispatch(actions.loadDrivers(fileData));
    client.loadDrivers(getState().plan.sessionID, fileData).then(response => response.text()).then(data => {
      if (data==='SESSION TIMEOUT') {localStorage.setItem('vehicle_router_status', 'timedout'); window.location.reload();}
      else {
        setTimeout(() => { dispatch(clientStateOperations.refreshPlan()); }, 3000);
      }
    });
  };

    export const leavePage: ThunkCommandFactory<void, LeavePageAction> = (
      () => (dispatch, getState, client) => {
        client.leavePage(getState().plan.sessionID);
      });

    export const refreshPlan: ThunkCommandFactory<void, RefreshPlanAction | UpdateRouteAction> = (
      () => (dispatch, getState, client) => {
        if (!(getState().client.isLoading || getState().client.isDriversLoading || getState().plan.isSolving || getState().plan.isCleaning)) return;
        client.refreshPlan(getState().plan.sessionID).then((response) => response.json()).then(plan => {dispatch(routeOperations.updateRoute(plan));
           if (getState().client.isLoading || getState().client.isDriversLoading) {
              if (!plan.isLoading) dispatch(clientStateOperations.finishLoading());
              else setTimeout(() => {dispatch(clientStateOperations.refreshPlan())}, 5000);
           } else if (getState().plan.isSolving || getState().plan.isCleaning)
           setTimeout(() => {dispatch(clientStateOperations.refreshPlan())}, 5000) });
      });

    export const restorePlan: ThunkCommandFactory<string, RestorePlanAction | UpdateRouteAction> = (
      (date) => (dispatch, getState, client) => {
        let ifBusy = false;
        if (date.startsWith('*')) {  //If date starts with * then "Open Anyway" was chosen, and we should open plan even if already active in another session
          date = date.substring(1);
          ifBusy = true;
        }
        dispatch(actions.restorePlanAction(date));
        client.restorePlan(getState().plan.sessionID, date, ifBusy).then((response) => response.json()).then(plan => {
          if (plan.response === 'ERROR') dispatch(clientStateOperations.showErrorMessage('Error|Sorry, there was a problem loading this plan.')); else 
          if (plan.response === 'PLAN IN USE') dispatch(clientStateOperations.showErrorMessage('Plan in Use;' + date + '|Sorry, this plan is currently in use.  Please choose a different plan.')); else 
          dispatch(routeOperations.updateRoute(plan)); dispatch(clientStateOperations.finishLoading())});
      });