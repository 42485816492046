/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Viewport as LeafletViewport } from 'react-leaflet';
import { Action } from 'redux';

export enum ActionType {
  UPDATE_VIEWPORT = 'UPDATE_VIEWPORT',
  RESET_VIEWPORT = 'RESET_VIEWPORT',
  ZOOM_VIEWPORT = 'ZOOM_VIEWPORT'
}

export interface UpdateViewportAction
  extends Action<ActionType.UPDATE_VIEWPORT> {
  value: LeafletViewport;
}

export interface ResetViewportAction
  extends Action<ActionType.RESET_VIEWPORT> {}

  export interface ZoomViewportAction
  extends Action<ActionType.ZOOM_VIEWPORT> {
  center: [number, number]
}

export interface UserViewport {
  isDirty: boolean;
  center: [number, number];
  zoom: number;
}

export type ViewportAction = UpdateViewportAction | ResetViewportAction | ZoomViewportAction;
