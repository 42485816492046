import React from 'react';
import { Modal} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

export interface Props {
  text: string;
  closeAlert: any;
  restorePlanHandler: any;
}

//"<div style={{whiteSpace:\"nowrap\"}}>" + 

class AlertBox extends React.Component<Props> {

  render() {
    let wrapStatus : any = this.props.text.startsWith("Plan Log") ? "nowrap" : "wrap";
    // AlertBox modal adjusted in App.css

    function getTitle(s: string) {
      let i = s.indexOf('|');
      if (s.indexOf(';') < i && s.indexOf(';') > 0) i = s.indexOf(';');
      return s.substring(0, i);
    }
  return (
    <>
    <Modal show={this.props.text.length>0} dialogClassName={"AlertBoxModal"} >
      <Alert variant={this.props.text.startsWith("Error") || this.props.text.startsWith("Plan in Use") ? "danger" : "warning"} >
        <Alert.Heading>{getTitle(this.props.text)}</Alert.Heading>
        <p dangerouslySetInnerHTML={{ __html: this.props.text.substring(this.props.text.indexOf('|')+1) }} style={{maxHeight: 'calc(100vh - 250px)', overflowY: "scroll", whiteSpace: wrapStatus }} />
        <hr />
        <div className="d-flex justify-content-end">
          {this.props.text.startsWith("Plan in Use")?<><Button onClick={()=>{this.props.restorePlanHandler('*'+this.props.text.substring(this.props.text.indexOf(';')+1, this.props.text.indexOf('|')), true)}} variant={"outline-danger"} >
            Open Anyway
          </Button>&nbsp;&nbsp;</>:null}
          <Button onClick={this.props.closeAlert} variant={this.props.text.startsWith("Error") || this.props.text.startsWith("Plan in Use") ? "outline-danger" : "outline-warning"} >
            Close
          </Button>
        </div>
      </Alert></Modal>
    </>
  );
  }
}

export default AlertBox;