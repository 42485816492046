/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ActionFactory } from '../types';
import { ActionType, FinishLoadingAction, LoadFileAction, LoadDriversFileAction, SavePlanAction, RestorePlanAction, LeavePageAction, RefreshPlanAction, ShowErrorMessageAction } from './types';

export const loadClients: ActionFactory<string, LoadFileAction> = (fileData) => ({
  type: ActionType.LOAD_FILE,
  fileData,
});

export const loadDrivers: ActionFactory<string, LoadDriversFileAction> = (fileData) => ({
  type: ActionType.LOAD_DRIVERS_FILE,
  fileData,
});

export const finishLoading: ActionFactory<void, FinishLoadingAction> = () => ({
  type: ActionType.FINISH_LOADING,
});

export const savePlanAction: ActionFactory<void, SavePlanAction> = () => ({
  type: ActionType.SAVE_PLAN,
});

export const leavePageAction: ActionFactory<void, LeavePageAction> = () => ({
  type: ActionType.LEAVE_PAGE,
});

export const refreshPlanAction: ActionFactory<void, RefreshPlanAction> = () => ({
  type: ActionType.REFRESH_PLAN,
});

export const showErrorMessageAction: ActionFactory<string, ShowErrorMessageAction> = (message) => ({
  type: ActionType.SHOW_ERROR_MESSAGE,
  message
});

export const restorePlanAction: ActionFactory<string, RestorePlanAction> = (date) => ({
  type: ActionType.RESTORE_PLAN,
  date
});