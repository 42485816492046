import { faHomeUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { Map, TileLayer } from 'react-leaflet';
import { Problem, Vehicle, Location, LatLngWithDescription } from "store/plan/types";
import LocationMarker from "./LocationMarker";
import { backendUrl } from 'common';

// This AddressEditor Modal is used in 5 circumstances.
// 1: Adding a driver address
// 2: Editing a driver address
// 3: Fixing an import problem
// 4: Adding a client address
// 5: Editing a client address

const modalTitle = ["Add Driver", "Edit Address", "Fix Import Problem", "Add Client", "Edit Address"];

export interface Props {
  show: any;
  handleClose: any;
  location?: Vehicle | Location;  // Undefined is used for adding a driver/client
  isDriver: boolean
  problems?: Problem[];
  handleDiscardProblem?: any;
  handleRemoveClient?: any;
  handleUpdateAddress: any;
}

export interface State {
  id: number;
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  lat: number;
  lng: number;
  geoAddresses: LatLngWithDescription[];
  isAddressValid: boolean;
  modalState: number;
  isWorking: boolean;
  isDriver: boolean;
}

class AddressEditor extends React.Component<Props, State> {
  leafletMap : React.RefObject<Map>;
  problems : Problem[] = [];

  constructor(props: Props) {
    super(props);
    this.leafletMap = React.createRef();
    this.getGeoCode = this.getGeoCode.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.discard = this.discard.bind(this);

    //if (typeof props.problems !== 'undefined') {
    if (props.problems !== undefined && props.problems.length > 0) {
      this.state = {
        id: props.problems[0].id,
        name: props.problems[0].name? props.problems[0].name : '',
        street: props.problems[0].street? props.problems[0].street : '',
        city: props.problems[0].city? props.problems[0].city : '',
        state: props.problems[0].state? props.problems[0].state : 'PA',
        zip: props.problems[0].zip? props.problems[0].zip : '',
        lat: props.problems[0].lat,
        lng: props.problems[0].lng,
        geoAddresses: [],
        isAddressValid: true,
        modalState: 3,
        isWorking: false,
        isDriver: props.isDriver,
      };
      this.problems = JSON.parse(JSON.stringify(props.problems)); // make a deep copy
      return;
    }
    this.state = {
      id: props.location? props.location.id : 0,
      name: props.location? props.location.name ?? '' : '',
      street: props.location? props.location.street ?? '' : '',
      city: props.location? props.location.city ?? '' : '',
      state: props.location? props.location.state ?? '' : 'PA',
      zip: props.location? props.location.zip ?? '' : '',
      lat: props.location? props.location.lat : 0, //39.96574230409412,
      lng: props.location? props.location.lng : 0, //-75.18096611680342,
      geoAddresses: [],
      isAddressValid: true,
      modalState: props.isDriver ? (props.location? 2 : 1) : (props.location? 5 : 4),
      isWorking: false,
      isDriver: props.isDriver,
    };
    
  }

  componentDidMount() { 
    if (this.leafletMap.current != null) this.leafletMap.current.leafletElement.invalidateSize();
    //if (typeof this.props.location !== 'undefined') this.getGeoCode();
    if (this.state.zip !=='') this.getGeoCode();
  } 

  mapClick(e: any) {
    this.setState({ lat: e.latlng.lat, lng: e.latlng.lng });
    //this.reverseGeoCode(e.latlng.lat+","+e.latlng.lng);
  }

  getGeoCode() {
    var updateCoords = (this.state.geoAddresses.length > 0); // Workaround to not update coordinates from Smarty on initial loading of modal
    if (typeof this.props.location == 'undefined') updateCoords = true;

    fetch(`${backendUrl}/api/load/geocode`, {
      method: 'POST', headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json' }, body: JSON.stringify( {
          street: this.state.street,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip
        })}).then(response => response.json())
    .then(data => {
      if (data === null) return;
      
      //const address = data[0].address;
      //const zip = (address.length > 10) ? address.substring(address.length - 10, address.length - 5) : '';
      this.setState({ isAddressValid: data[0].zip===this.state.zip });
      if (updateCoords) this.setState({ lat: data[0].lat, lng: data[0].lng });

      var gas: LatLngWithDescription[] = [];
      for (let i = 0; i < data.length; i++) {
        const a : LatLngWithDescription = {
          description: data[i].address,
          lat: data[i].lat,
          lng: data[i].lng
        };
        gas.push(a);
      }
      this.setState({ geoAddresses: gas });
    });
  }

  selectCandidate(index : any) {
    this.setState({ lat: this.state.geoAddresses[index].lat, lng: this.state.geoAddresses[index].lng });
  }

  saveChanges() {
    if (this.state.lat === 0) {
      this.setState({ isAddressValid: false });
      return;
    }
    if (this.state.name === '' || this.state.city === '' || this.state.zip === '') return;

    this.setState({ isWorking: true });
    this.props.handleUpdateAddress(JSON.stringify(this.state));
    
    if (this.problems.length > 1) this.showNextProblem(); else this.props.handleClose(); 
    this.setState({ isWorking: false });
  }

  showNextProblem() {
    this.problems.shift();
    this.setState({
      id: this.problems[0].id,
      name: this.problems[0].name? this.problems[0].name : '',
      street: this.problems[0].street? this.problems[0].street : '',
      city: this.problems[0].city? this.problems[0].city : '',
      state: this.problems[0].state? this.problems[0].state : 'PA',
      zip: this.problems[0].zip? this.problems[0].zip : '',
      lat: this.problems[0].lat,
      lng: this.problems[0].lng,
      geoAddresses: [],
      isAddressValid: true,
      modalState: 3,
     }, () => {if (this.state.zip !=='') this.getGeoCode();});
  }

  discard() {
    if (this.state.modalState === 3) {  // Discard a problem
      this.props.handleDiscardProblem(this.state.id);
      if (this.problems.length > 1) this.showNextProblem(); else this.props.handleClose();
    }
    if (this.state.modalState === 5) {  // Discard a client
      this.props.handleRemoveClient(this.state.id);
      this.props.handleClose();
    }
  }

  render() {
    const props = this.props;
    return (
        <Modal show={props.show} onHide={props.handleClose} scrollable={true} dialogClassName={"AddressEditorModal"} centered >
        <Modal.Header closeButton>
        <Modal.Title style={{color:'#0D6EFD'}}><FontAwesomeIcon icon={faHomeUser} style={{color: '#1A8754', fontSize:'22px'}} /> {modalTitle[this.state.modalState-1]}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <Row><Col style={{width:'600px'}}>
        <Form>
        <Form.Group className="mb-3" controlId="formGridName">
          <Form.Label>Name</Form.Label>
          <Form.Control value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formGridAddress">
          <Form.Label>Address</Form.Label>
          <Form.Control value={this.state.street} onChange={(e) => this.setState({ street: e.target.value })} />
        </Form.Group>
  
          <Form.Group className="mb-3" controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
          </Form.Group>
  
          <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>State</Form.Label>
            <Form.Select value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}>
              <option>CT</option>
              <option>DE</option>
              <option>MD</option>
              <option>NJ</option>
              <option>NY</option>
              <option>PA</option>
            </Form.Select>
          </Form.Group>
  
          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>Zip</Form.Label>
            <Form.Control value={this.state.zip} onChange={(e) => this.setState({ zip: e.target.value.trim() })} />
          </Form.Group>
        </Row>
  
      </Form>
      </Col>
      <Col>
      <Map ref={this.leafletMap} center={this.state} zoom={16} style={{width:'380px', height:'320px'}} onclick={(e:any) => this.mapClick(e)} >
      <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker
         key={1}
         location={this.state}
         type={'client'}
         route={1}
         isSelected={false}
         selectLocationHandler={()=>{}}
         driver={''}
       />
      </Map>
      </Col>
      </Row>
      <Form.Label>SmartyStreets GeoCode:</Form.Label>
      <InputGroup className="mb-3" hasValidation>
        <Button variant="success" id="button-addon1" onClick={this.getGeoCode}>
          Lookup
        </Button>
        <Form.Select id='g1' isInvalid={!this.state.isAddressValid} onChange={(e: any) => this.selectCandidate(e.target.value)}>
          {this.state.geoAddresses.map((a, i) => <option value={i} key={i}>{a.description}</option> )}
      </Form.Select>
        <Form.Control.Feedback type="invalid">
        Address from SmartyStreets does not seem to match.
      </Form.Control.Feedback>
      </InputGroup>

      </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            {this.state.modalState === 3 || this.state.modalState === 5 ? <Button variant="danger" onClick={this.discard}>Discard</Button> : ''}
          </div>
          <div>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button variant="primary" className={!this.state.isWorking ? 'd-none' : ''}>
          <Spinner animation="border" size="sm" />&nbsp;Submit
          </Button>
          <Button variant="primary" onClick={this.saveChanges} className={this.state.isWorking ? 'd-none' : ''}>
          Submit
          </Button>
          </div>
        </Modal.Footer>
    </Modal>
    );
  }
};
  
export default AddressEditor;
