/*
 * Copyright 2018 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ActionType, PlanAction, Plan } from './types';

export const initialRouteState: Plan = {
  sessionID: '', //Math.random().toString(36).substr(2, 9),
  isSolving: false,
  isCleaning: false,
  isLoading: false,
  useWindows: true,
  hasRoutes: false,
  distance: 'no data',
  vehicles: [],
  visits: [],
  routes: [],
  problems: [],
  planID: '',
  windowCount: '',
};

export const routeReducer = (state = initialRouteState, action: PlanAction): Plan => {
  switch (action.type) {
    case ActionType.UPDATE_ROUTING_PLAN: {
      return { ...state, distance: action.plan.distance, hasRoutes: (action.plan.distance==="0h 0m 0s" ? false : true), vehicles: action.plan.vehicles, visits: action.plan.visits, routes: action.plan.routes, problems: action.plan.problems, planID: action.plan.planID, windowCount: action.plan.windowCount, isLoading: action.plan.isLoading};
    }
    case ActionType.START_SOLVE: {
      return { ...state, isSolving: true};
    }
    case ActionType.START_CLEAN: {
      return { ...state, isCleaning: true};
    }
    case ActionType.STOP_SOLVE: {
      return { ...state, isSolving: false, isCleaning: false };
    }
    case ActionType.MOVE_LOCATIONS: {
      return { ...state, isSolving: false};
    }
    case ActionType.TOGGLE_USE_WINDOWS: {
      return { ...state, useWindows: !state.useWindows};
    }
    case ActionType.SET_SESSIONID: {
      return { ...state, sessionID: action.sessionID};
    }
    default:
      return state;
  }
};
