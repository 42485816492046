/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as L from 'leaflet';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { Location } from 'store/plan/types';
import { Colors } from './Colors';

const colors = new Colors();  // TODO: make this static?

export interface Props {
  location: Location;
  type: string;
  route: number;
  isSelected: boolean;
  driver: string;
  arrivalTime?: number;
  multiStop?: number;
  selectLocationHandler: (id: number) => void;
}

const LocationMarker: React.FC<Props> = ({
  location,
  type,
  route,
  isSelected,
  driver,
  arrivalTime,
  multiStop, 
  selectLocationHandler,
}) => {

  var icon : L.Icon;
  switch(type) {
    case 'client' : icon = colors.getClientIcon(); break;
    case 'driver' : icon = colors.getDriverIcon(); break;
    case 'home' : icon = colors.getHomeIcon(route); break;
    case 'unassigned' : icon = colors.getUnassignedIcon(multiStop); break;
    default: icon = colors.getMapIcon(route, multiStop);
  }

  
  const markerRef = useRef<Marker>(null);

    function updateIcon() {
      if (type === 'route' || type === 'selected') {
        markerRef.current?.leafletElement.setIcon(type === 'route' ? icon : colors.getSelectedIcon(multiStop));
      }
    }

  function formatTime(s: number) {

    function pad(n: number) {
      return ('00' + n).slice(-2);
    }

    var mod = " AM";
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
    if (hrs > 11) mod = " PM";
    if (hrs > 12) hrs = hrs - 12;

    return pad(hrs) + ':' + pad(mins) + mod;
  }

  function formatWindow(l : Location) {
    if (l.window_start != null && l.window_end != null) {
      return formatTime(l.window_start*1800000) + " - " + formatTime(l.window_end*1800000);
    }
  }

  useEffect(() => {
    updateIcon()
},[type]) // <-- here put the parameter to listen

  return (
    <Marker
      ref={markerRef}
      key={location.id}
      position={location}
      icon={icon}
      zIndexOffset={(type ==='unassigned' || type === 'selected' || multiStop === -1) ? 1000 : 0}  // make sure selected & unassigned markers are on top
      onclick={() => {selectLocationHandler(location.id);}}
 
    >
      <Tooltip
        // `permanent` is a static property (this is a React-Leaflet-specific
        // approach: https://react-leaflet.js.org/docs/en/components). Changing `permanent` prop
        // doesn't result in calling `setPermanent()` on the Leaflet element after the Tooltip component is mounted.
        // We're using `key` to force re-rendering of Tooltip when `isSelected` changes. A similar use case for
        // the `key` property is described here:
        // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
        // #recommendation-fully-uncontrolled-component-with-a-key

        key={isSelected ? 'selected' : ''}
        permanent={isSelected}
      >
        <b>{location.name}</b><br />{location.street}<br />{location.city}, {location.state} {location.zip}<br />{location.phone}
        {(driver !== '') ? <div><hr style={{margin: '3px'}} /><b>{driver}</b></div> : ''}{location.window_start!=null && location.window_start>0 ? <div><b>Window:</b> {formatWindow(location)}</div> : ''}{arrivalTime ? <div><b>Arrival:</b> {formatTime(arrivalTime)}</div> : ''}
      </Tooltip>
    </Marker>
  );
};

export default LocationMarker;

