/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ActionType, ClientState, ClientStateAction } from './types';

const initialState: ClientState = {
  isLoading: false,
  isDriversLoading: false,
  isRestoring: false,
  fileData: null,
  errorMessage: "",
};

export const clientStateReducer = (state = initialState, action: ClientStateAction): ClientState => {
  switch (action.type) {
    case ActionType.LOAD_FILE: {
      return { ...initialState, isLoading: true, fileData: action.fileData };
    }
    case ActionType.LOAD_DRIVERS_FILE: {
      return { ...initialState, isDriversLoading: true, fileData: action.fileData };
    }
    case ActionType.RESTORE_PLAN: {
      return { ...initialState, isRestoring: true };
    }
    case ActionType.SHOW_ERROR_MESSAGE: {
      return {...initialState, errorMessage: action.message}
    }
    case ActionType.FINISH_LOADING: {
      return { ...state, isLoading: false, isDriversLoading: false, isRestoring: false };
    }
    default:
      return state;
  }
};
