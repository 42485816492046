/*
 * Copyright 2018 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
// it's possible to disable the extension in production
// by importing from redux-devtools-extension/developmentOnly
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import WebSocketClient from 'websocket/WebSocketClient';
import clientReducer from './map';
import clientStateReducer from './client';
import planReducer from './plan';
import { AppState } from './types';

export interface StoreConfig {
  readonly backendUrl: string;
}

export function configureStore(
  { backendUrl }: StoreConfig,
  preloadedState?: AppState,
): Store<AppState> {
  const webSocketClient = new WebSocketClient(backendUrl);

  const middlewares = [thunk.withExtraArgument(webSocketClient), createLogger()];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  // map reducers to state slices
  const rootReducer = combineReducers<AppState>({
    client: clientStateReducer,
    plan: planReducer,
    map: clientReducer,
  });

  return createStore(
    rootReducer,
    preloadedState,  // Initial state
    composedEnhancers,
  );
}
