/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

  import * as React from 'react'; 
  import { connect } from 'react-redux';
  import { clientOperations } from 'store/map';
  import { UserViewport } from 'store/map/types';
  import { clientStateOperations } from 'store/client';
  import { routeOperations, routeSelectors } from 'store/plan';
  import { Location, RouteWithTrack, Vehicle, Problem } from 'store/plan/types';
  import { AppState } from 'store/types';
  import RouteMap from 'ui/components/RouteMap';
  import { Button, Spinner } from 'react-bootstrap';
  import DriverList from './components/DriverList';
  import Toolbar from './components/Toolbar';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faPlay } from '@fortawesome/free-solid-svg-icons';
import AddressEditor from './components/AddressEditor';
  
  export const ID_CLEAR_BUTTON = 'clear-button';
  export const ID_IMPORT_BUTTON = 'import-button';
  export const ID_EXPORT_BUTTON = 'export-button';
  
  export interface StateProps {
    distance: string;
    windowCount: string;
    vehicleCount: number;
    totalCapacity: number;
    totalDemand: number;
    visits: Location[];
    routes: RouteWithTrack[];
    problems: Problem[];
    vehicles: Vehicle[];
    hasRoutes: boolean;
    isDemoLoading: boolean;
    userViewport: UserViewport;
    isSolving: boolean;
    isCleaning: boolean;
    useWindows: boolean;
  }
  
  export interface DispatchProps {
    loadHandler: typeof clientStateOperations.loadFile;
    clearHandler: typeof routeOperations.clearRoute;
    startCleanHandler: typeof routeOperations.startClean;
    stopSolveHandler: typeof routeOperations.stopSolve;
    toggleUseWindowsHandler: typeof routeOperations.toggleUseWindows;
    removeLocationHandler: typeof routeOperations.deleteLocation;
    moveLocationsHandler: typeof routeOperations.moveLocations;
    swapDriversHandler: typeof routeOperations.swapDrivers;
    updateViewport: typeof clientOperations.updateViewport;
    zoomViewport: typeof clientOperations.zoomViewport;
    updateDriversHandler: typeof routeOperations.updateDrivers;
    discardProblemHandler: typeof routeOperations.discardProblem;
    removeClientHandler: typeof routeOperations.deleteLocation;
    
  }

  const styles = {
    row: {
      display: 'flex',
    }, 
    col1: {
        paddingLeft: 0,
        paddingRight: 0, 
        width: 'calc(100vw - 300px)'
    },
    col2: {
      paddingLeft: 10,
      paddingRight: 10, 
      width: '300px'
    }
};
  
  const mapStateToProps = ({ plan, client, map }: AppState): StateProps => ({
    distance: plan.distance,
    windowCount: plan.windowCount,
    vehicleCount: plan.vehicles.length,
    totalCapacity: routeSelectors.totalCapacity(plan),
    totalDemand: routeSelectors.totalDemand(plan),
    visits: plan.visits,
    routes: plan.routes,
    problems: plan.problems,
    vehicles: plan.vehicles,
    hasRoutes: plan.hasRoutes,
    isDemoLoading: client.isLoading,
    isSolving: plan.isSolving,
    isCleaning: plan.isCleaning,
    useWindows: plan.useWindows,
    userViewport: map,
  });
  
  const mapDispatchToProps: DispatchProps = {
    loadHandler: clientStateOperations.loadFile,
    clearHandler: routeOperations.clearRoute,
    startCleanHandler: routeOperations.startClean,
    stopSolveHandler: routeOperations.stopSolve,
    toggleUseWindowsHandler: routeOperations.toggleUseWindows,
    removeLocationHandler: routeOperations.deleteLocation,
    moveLocationsHandler: routeOperations.moveLocations,
    swapDriversHandler: routeOperations.swapDrivers,
    updateViewport: clientOperations.updateViewport,
    zoomViewport: clientOperations.zoomViewport,
    updateDriversHandler: routeOperations.updateDrivers,
    discardProblemHandler: routeOperations.discardProblem,
    removeClientHandler: routeOperations.deleteLocation,
  };
  
  export type DemoProps = DispatchProps & StateProps;
  
  export interface DemoState {
    selectedId: number;
    selectedRouteId: number;
    selectedLocations: number[];
    unAssignedVisits: Location[]|null;
    showProblems: boolean;
  }
  
  export class Main extends React.Component<DemoProps, DemoState> {

    constructor(props: DemoProps) {
      super(props);

      this.state = {
        selectedId: NaN,
        selectedRouteId: 0,
        selectedLocations : [],
        unAssignedVisits: null,
        showProblems: false,
      };
  
      this.handleDemoLoadClick = this.handleDemoLoadClick.bind(this);
      this.onSelectLocation = this.onSelectLocation.bind(this);
      this.onClickDriver = this.onClickDriver.bind(this);
      this.onFindClient = this.onFindClient.bind(this);
      this.selectLocationHandler = this.selectLocationHandler.bind(this);
      this.selectLocationsHandler = this.selectLocationsHandler.bind(this);
      this.clearSelectedLocations = this.clearSelectedLocations.bind(this);
      this.moveSelectedLocations = this.moveSelectedLocations.bind(this);
      this.swapDrivers = this.swapDrivers.bind(this);
      this.setUnAssignedVisits = this.setUnAssignedVisits.bind(this);
      this.cleanup = this.cleanup.bind(this);
      this.stopCleanup = this.stopCleanup.bind(this);
      this.hideProblems = this.hideProblems.bind(this);
      this.discardProblem = this.discardProblem.bind(this);
      this.updateProblem = this.updateProblem.bind(this);
    }

    setUnAssignedVisits(visits: Location[]|null) {
      this.setState({ unAssignedVisits: visits });
    }
  
    handleDemoLoadClick(demoName: string) {
      this.props.loadHandler(demoName);
    }
  
    onSelectLocation(id: number) {
      this.setState({ selectedId: id });
      //this.setState({ selectedRouteId: this.props.routes[id].vehicle.id });
    }

    onClickDriver(id: number) {
      if (id === -1) { // select all unassigned clients
        this.setState({ selectedLocations: Array.from(new Set<number>(this.state.unAssignedVisits?.map(x => x.id))) });
        return;
      }
      if (id === -2) { // show problems dialog
        this.setState({ showProblems: true });
        return;
      }
      const v : Vehicle[] = this.props.vehicles.filter(vehicle => vehicle.id === id)
      if (v.length === 1) {
        //this.props.zoomViewport([v[0].lat, v[0].lng]);
        this.props.userViewport.center = [v[0].lat, v[0].lng];
        this.props.updateViewport(this.props.userViewport);
      }
    }

    hideProblems() {
      this.setState({ showProblems: false });
    }

    discardProblem(problemID: number) {
      this.props.discardProblemHandler(problemID);
    }

    updateProblem(edits: string) {
      this.props.updateDriversHandler(edits);
    }

    // show client from toolbar search
    onFindClient(id: number) {
      const v : Location[] = this.props.visits.filter(visit => visit.id === id)
      if (v.length === 1) {
        //this.props.zoomViewport([v[0].lat, v[0].lng]);
        this.props.userViewport.center = [v[0].lat, v[0].lng];
        if (this.props.userViewport.zoom < 10) this.props.userViewport.zoom = 10;
        this.props.updateViewport(this.props.userViewport);
        const a : number[] = [];
        a.push(id);
        this.setState({ selectedLocations: a });
      }
    }

    // Handler for selecting locations one at a time, they can be unselected this way as well
    selectLocationHandler_orig(id: number) {
      const i = this.state.selectedLocations.indexOf(id);
      if (i > -1) {
        this.setState({ selectedLocations: this.state.selectedLocations.filter(selectedLocation => selectedLocation !== id)});
      } else {
        this.setState({ selectedLocations: [...this.state.selectedLocations, id] });
      }
    }

    selectLocationHandler(locid: number) {
      const i = this.state.selectedLocations.indexOf(locid);
      const unSelect : boolean =  i > -1 ? true : false;  // should I select them, or unselect them?
      let ids : number[] = [locid];

      // See if there are other pins at same location, if so: select them all
      const l : Location[] = this.props.visits.filter(item => item.id === locid);
      if (l.length === 1) {
        ids = this.props.visits.filter(item => (item.lat === l[0].lat && item.lng === l[0].lng)).map(item => item.id);
      }

      for (const id of ids) {
        if (unSelect) this.setState({ selectedLocations: this.state.selectedLocations.filter(selectedLocation => selectedLocation !== id)});
        else this.setState({ selectedLocations: [...this.state.selectedLocations, id] });
      }
    }

    // Handler for box selecting multiple locations, they cannot be unselected
    selectLocationsHandler(ids: number[]) {
      this.setState({ selectedLocations: Array.from(new Set<number>([...this.state.selectedLocations, ...ids])) });
    }



    clearSelectedLocations() {
      this.setState({ selectedLocations: [] });
    }

    moveSelectedLocations(driverId : string) {
      this.props.moveLocationsHandler(driverId + "," + this.state.selectedLocations.toString());
    }

    swapDrivers(driverIds: string) {
      this.props.swapDriversHandler(driverIds);
    }

    cleanup() {
      if (!this.props.isSolving) this.props.startCleanHandler();
    }

    stopCleanup() {
      this.props.stopSolveHandler();
    }

   // openModal = () => this.setState({ isModelOpen: true });
  
    render() {
      const { selectedId, unAssignedVisits } = this.state;
      const {
        distance,
        windowCount,
        visits,
        routes,
        problems,
        vehicles,
        hasRoutes,
        userViewport,
        removeLocationHandler,
        updateViewport,
      } = this.props;

      /*const filteredRoutes = (
        routes.filter(route => route.vehicle.id === selectedId).length === 0 ? routes : 
        routes.map((value, index) => (value.vehicle.id === selectedId ? value : { visits: [], track: [] , vehicle: value.vehicle, distance: value.distance}))
      );*/
  
      return (
        <div style={styles.row}>
        <div style={styles.col1}>
        <Toolbar 
          visits={visits}
          vehicles={vehicles}
          selectedLocations={this.state.selectedLocations}
          clearSelectedLocations={this.clearSelectedLocations}
          moveSelectedLocations={this.moveSelectedLocations}
          swapDrivers={this.swapDrivers}
          onFindClient = {this.onFindClient}
          updateDriversHandler = {this.props.updateDriversHandler}
          removeClientHandler = {this.props.removeClientHandler}
        />
        <RouteMap
              userViewport={userViewport}
              updateViewport={updateViewport}
              selectedId={selectedId}
              selectedLocations={this.state.selectedLocations}
              selectLocationHandler={this.selectLocationHandler}
              selectLocationsHandler={this.selectLocationsHandler}
              removeHandler={removeLocationHandler}
              vehicles={vehicles}
              routes={routes}
              visits={visits}
              hasRoutes={hasRoutes}
              unAssignedVisits={unAssignedVisits}
              setUnAssignedVisits={this.setUnAssignedVisits}
            /></div>
        <div style={styles.col2}><h5 style={{marginBottom:'5px'}}><b>Driving Time: </b>{distance}</h5>
        <h5 style={{marginBottom:'5px'}}><b>Windows: </b>{windowCount}</h5>
        <div style={{height:'35px', lineHeight:'40px'}}><span><b>List of Routes:</b></span>
        <Button variant="danger" onClick={this.stopCleanup} className={this.props.isCleaning ? 'float-end' : 'd-none'} size="sm" style={{width:'120px'}}>
      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>&nbsp;
      Stop</Button>
      <Button variant="success" onClick={this.cleanup} className={!this.props.isCleaning ? 'float-end' : 'd-none'} size="sm" style={{width:'120px'}}>
      <FontAwesomeIcon icon={faPlay} /> Clean Up
          </Button></div>
          {this.state.showProblems? <AddressEditor show={true} isDriver={true} problems={this.props.problems} handleClose={this.hideProblems} handleDiscardProblem={this.discardProblem} handleUpdateAddress={this.updateProblem}/> : ''}
        <DriverList
            visits={vehicles}
            routes={routes}
            hasRoutes={hasRoutes}
            problems={problems}
            removeHandler={removeLocationHandler}
            selectHandler={this.onSelectLocation}
            clickHandler={this.onClickDriver}
            unAssignedCount={unAssignedVisits == null ? 0 : unAssignedVisits.length}
          />
        
        </div>
        </div>
      );
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Main);
  