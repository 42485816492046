import React from 'react';
import { Modal, Spinner} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { backendUrl } from 'common';
import { timeStamp } from 'console';

export interface Props {
  closeAlert: any;
}

export interface State {
  text: string
}

class Availability extends React.Component<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = { text: "Please wait..." };
  }
  

  componentDidMount() {
    //let s : string = "<table border='1' cellspacing='0' cellpadding='8' style='font-family:arial;'>";
    //s = s + "<tr bgcolor='#d0e2ff'><th>Date</th><th>% Filled</th><th>Time Available</th></tr>";
    //s = s + "<tr><td>MON 01/02</td><td><svg xmlns='http://www.w3.org/2000/svg' width='50' height='10'><g class='bar'><rect width='50' height='10' fill='#12D0A6'></rect></g></svg> 50%</td></tr>";
    //s = s + "<tr><td>TUE 01/03</td><td><svg xmlns='http://www.w3.org/2000/svg' width='40' height='10'><g class='bar'><rect width='40' height='10' fill='#F8913F'></rect></g></svg> 40%</td></tr>";
    //s = s + "</table>";
    //this.setState({text: s});
    //return;
    
    fetch(`${backendUrl}/api/plan/availability`, {
      method: 'POST',
    }).then(response => response.text())
    .then(data => {
      this.setState({ text: data });
    });
  }

  render() {
    // AlertBox modal adjusted in App.css
    
  return (
    <>
    <Modal show={true} dialogClassName={"AlertBoxModal"} >
      <Alert variant= "warning" >
        <Alert.Heading>Schedule Availability</Alert.Heading>
        {this.state.text.startsWith('Please') ? <><Spinner animation="border" size="sm" /> Please wait...</> : <p dangerouslySetInnerHTML={{ __html: this.state.text }} style={{maxHeight: 'calc(100vh - 250px)', whiteSpace: "nowrap" }} />}
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={this.props.closeAlert} variant={"outline-primary"} >
            Close
          </Button>
        </div>
      </Alert></Modal>
    </>
  );
  }
}

export default Availability;