/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Action } from 'redux';

export enum ActionType {
  LOAD_FILE = 'LOAD_FILE',
  LOAD_DRIVERS_FILE = 'LOAD_DRIVERS_FILE',
  FINISH_LOADING = 'FINISH_LOADING',
  SAVE_PLAN = 'SAVE_PLAN',
  RESTORE_PLAN = 'RESTORE_PLAN',
  LEAVE_PAGE = 'LEAVE_PAGE',
  REFRESH_PLAN = 'REFRESH_PLAN',
  SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE',
}

export interface LoadFileAction extends Action<ActionType.LOAD_FILE> {
  readonly fileData: string;
}

export interface LoadDriversFileAction extends Action<ActionType.LOAD_DRIVERS_FILE> {
  readonly fileData: string;
}

export interface SavePlanAction extends Action<ActionType.SAVE_PLAN> {
}

export interface RestorePlanAction extends Action<ActionType.RESTORE_PLAN> {
  readonly date: string;
}

export interface LeavePageAction extends Action<ActionType.LEAVE_PAGE> {
}

export interface RefreshPlanAction extends Action<ActionType.REFRESH_PLAN> {
}

export interface ShowErrorMessageAction extends Action<ActionType.SHOW_ERROR_MESSAGE> {
  readonly message: string;
}

export interface FinishLoadingAction
  extends Action<ActionType.FINISH_LOADING> {}

export type ClientStateAction = LoadFileAction | LoadDriversFileAction | FinishLoadingAction | SavePlanAction | RestorePlanAction | LeavePageAction | RefreshPlanAction | ShowErrorMessageAction;

export interface ClientState {
  readonly isLoading: boolean;
  readonly isDriversLoading: boolean;
  readonly isRestoring: boolean;
  readonly fileData: string | null;
  readonly errorMessage: string;
}
