/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export default class WebSocketClient {
  readonly backendUrl: string;

  constructor(backendUrl: string) {
    this.backendUrl = backendUrl;
  }

  moveLocations(sessionID: string, locationids: string): Promise<Response> {
    return fetch(`${this.backendUrl}/plan/location/move`, { method: 'POST', headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json' }, body: JSON.stringify( {
        sessionID: sessionID,
        ids: locationids
      })});
  }

  addDriver(sessionID: string, driver: string): Promise<Response> {
    return fetch(`${this.backendUrl}/plan/vehicle/add`, { method: 'POST', headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json' }, body: JSON.stringify( {
        sessionID: sessionID,
        driver: driver
      })});
    }

  editDrivers(sessionID: string, edits: string): Promise<Response> {
    return fetch(`${this.backendUrl}/plan/vehicle/edit`, { method: 'POST', headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json' }, body: JSON.stringify( {
        sessionID: sessionID,
        edits: edits
      })});
    }

    updateDrivers(sessionID: string, edits: string): Promise<Response> {
      return fetch(`${this.backendUrl}/plan/vehicle/update`, { method: 'POST', headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json' }, body: JSON.stringify( {
          sessionID: sessionID,
          edits: edits
        })});
      }

  loadClients(sessionID: string, fileData: string): Promise<Response> {
    return fetch(`${this.backendUrl}/load/clients`, { method: 'POST', headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json' }, body: JSON.stringify( {
        sessionID: sessionID,
        data: fileData
      })});
  }

  loadDrivers(sessionID: string, fileData: string): Promise<Response> {
    return fetch(`${this.backendUrl}/load/drivers`, { method: 'POST', headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json' }, body: JSON.stringify( {
        sessionID: sessionID,
        data: fileData
      })});
  }

  deleteLocation(sessionID: string, locationId: number): Promise<Response> {
   return fetch(`${this.backendUrl}/plan/location`, { method: 'DELETE', headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json' }, body: JSON.stringify( {
        sessionID: sessionID,
        id: locationId
      })});
  }



  clear(sessionID: string): Promise<Response> {
    return fetch(`${this.backendUrl}/plan/clear/${sessionID}`, { method: 'POST' });
  }

  swapDrivers(sessionID: string, vehicleIds: string): Promise<Response> {
      return fetch(`${this.backendUrl}/plan/vehicle/swap`, { method: 'POST', headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json' }, body: JSON.stringify( {
          sessionID: sessionID,
          ids: vehicleIds
        })});
    }

    discardProblem(sessionID: string, problemID: number): Promise<Response> {
      return fetch(`${this.backendUrl}/plan/problem`, { method: 'DELETE', headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json' }, body: JSON.stringify( {
          sessionID: sessionID,
          problemID: problemID
        })});
    }
  

  startSolve(sessionID: string, isLocked: Boolean, useWindows: Boolean): Promise<Response> {
    return fetch(`${this.backendUrl}/solver/start`, { method: 'POST', headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json' }, body: JSON.stringify( {
        sessionID: sessionID,
        isLocked: isLocked,
        useWindows: useWindows
      })});
  }

  stopSolve(sessionID: string): Promise<Response> {
    return fetch(`${this.backendUrl}/solver/stop/${sessionID}`, { method: 'POST' });
  }

  leavePage(sessionID: string): Promise<Response> {
    return fetch(`${this.backendUrl}/plan/cleanup/${sessionID}`, { method: 'POST' });
  }

  refreshPlan(sessionID : string): Promise<Response> {
    return fetch(`${this.backendUrl}/plan/refresh/${sessionID}`, { method: 'POST' });
  }

  restorePlan(sessionID: string, planID: string, ifBusy: boolean): Promise<Response> {
    return fetch(`${this.backendUrl}/plan/restore`, { method: 'POST', headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json' },
    body: JSON.stringify( {
      sessionID: sessionID,
      planID: planID,
      ifBusy: ifBusy
    } ) });
  }

  authenticate(password: string): Promise<Response> {
    return fetch(`${this.backendUrl}/authenticate`, { method: 'POST', headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json' }, body: JSON.stringify(password)});
  }

}


