import { backendUrl } from 'common';
import { faAmbulance } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Modal } from 'react-bootstrap';
import React, { useState } from "react";


const AddDriver = (props: { show: any; handleClose: any; handleSubmit: any, sessionID: string;}) => {

    let driverRef : React.RefObject<HTMLSelectElement> = React.createRef();
    const [drivers, setDrivers] = useState("");

function submit() {
    const d = driverRef.current?.value;
    if (d!=="") props.handleSubmit(d);
    props.handleClose();
}

function init() {
    fetch(`${backendUrl}/api/plan/listDrivers`, {
        method: 'POST', headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json' }, body: JSON.stringify( {
          sessionID: props.sessionID,
        })
      }).then(response => response.text())
      .then(data => {
        setDrivers(data);
      });
}

// AddDriver modal width adjusted in App.css
 return (
    <Modal show={props.show} onHide={props.handleClose} onEnter={init} dialogClassName={"AddDriverModal"} >
    <Modal.Header closeButton>
    <Modal.Title style={{color:'#0D6EFD'}}><FontAwesomeIcon icon={faAmbulance} />&nbsp;Add a Driver</Modal.Title>
    </Modal.Header>
    <Modal.Body >
    List of Active Drivers
    <Form.Select aria-label="label" ref={driverRef} style={{width:'400px'}}>
      <option value="">Select a driver...</option>
      {drivers.split("#").map((driver) => (<option value={driver}>{driver.substring(0, driver.indexOf('|'))}</option>))}
    </Form.Select>
    </Modal.Body>
    <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submit}>
            Add Driver
          </Button>
        </Modal.Footer>
    </Modal>
    );
}

export default AddDriver;