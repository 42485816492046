/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { clientStateOperations } from '../client';
import { ThunkCommandFactory } from '../types';
import * as actions from './actions';
import {
  ClearRouteAction,
  DeleteLocationAction,
  MoveLocationsAction,
  AddDriverAction,
  EditDriversAction,
  UpdateDriversAction,
  DiscardProblemAction,
  StartSolveAction,
  StopSolveAction,
  SwapDriversAction,
  StartCleanAction,
  ToggleUseWindowsAction,
  SetSessionIDAction,
  UpdateRouteAction,
} from './types';

export const { updateRoute } = actions;

function handleResponse(dispatch: any, plan: any) {
  if (plan.response === 'SESSION TIMEOUT') {
    localStorage.setItem('vehicle_router_status', 'timedout');
    window.location.reload();
  } else if (plan.response === 'ERROR') {
    localStorage.setItem('vehicle_router_status', 'error');
    window.location.reload();
  } else
  dispatch(updateRoute(plan));
}

export const deleteLocation: ThunkCommandFactory<number, DeleteLocationAction | UpdateRouteAction> = (
  (locationId) => (dispatch, getState, client) => {
    dispatch(actions.deleteLocation(locationId));
    client.deleteLocation(getState().plan.sessionID, locationId).then(response => response.json()).then(plan => {handleResponse(dispatch, plan);
    });
  });

  export const moveLocations: ThunkCommandFactory<string, MoveLocationsAction | UpdateRouteAction> = (
    (locationids) => (dispatch, getState, client) => {
      dispatch(actions.moveLocations(locationids));
      client.moveLocations(getState().plan.sessionID, locationids).then(response => response.json()).then(plan => {handleResponse(dispatch, plan);
    });
  });

    export const editDrivers: ThunkCommandFactory<string, EditDriversAction | UpdateRouteAction> = (
      (edits) => (dispatch, getState, client) => {
        dispatch(actions.editDrivers(edits));
        client.editDrivers(getState().plan.sessionID, edits).then(response => response.json()).then(plan => {handleResponse(dispatch, plan);
        });
      });

      export const addDriver: ThunkCommandFactory<string, AddDriverAction | UpdateRouteAction> = (
        (driver) => (dispatch, getState, client) => {
          dispatch(actions.addDriver(driver));
          client.addDriver(getState().plan.sessionID, driver).then(response => response.json()).then(plan => {handleResponse(dispatch, plan);
          });
        });

      export const updateDrivers: ThunkCommandFactory<string, UpdateDriversAction | UpdateRouteAction> = (
        (edits) => (dispatch, getState, client) => {
          dispatch(actions.updateDrivers(edits));
          client.updateDrivers(getState().plan.sessionID, edits).then(response => response.json()).then(plan => {handleResponse(dispatch, plan);
          });
        });

        export const discardProblem: ThunkCommandFactory<number, DiscardProblemAction | UpdateRouteAction> = (
          (problemID) => (dispatch, getState, client) => {
            dispatch(actions.discardProblem(problemID));
            client.discardProblem(getState().plan.sessionID, problemID).then(response => response.json()).then(plan => {handleResponse(dispatch, plan);
            });
          });

export const clearRoute: ThunkCommandFactory<void, ClearRouteAction | UpdateRouteAction> = (
  () => (dispatch, getState, client) => {
    dispatch(actions.clearRoute());
    client.clear(getState().plan.sessionID).then(response => response.json()).then(plan => {handleResponse(dispatch, plan);
    });
  });

  export const swapDrivers: ThunkCommandFactory<string, SwapDriversAction | UpdateRouteAction> = (
    (driverIds) => (dispatch, getState, client) => {
      dispatch(actions.swapDrivers(driverIds));
      client.swapDrivers(getState().plan.sessionID, driverIds).then(response => response.json()).then(plan => {handleResponse(dispatch, plan);
      });
    });

  export const startSolve: ThunkCommandFactory<void, StartSolveAction> = (
    () => (dispatch, getState, client) => {
      dispatch(actions.startSolve());
      client.startSolve(getState().plan.sessionID, false, getState().plan.useWindows).then(response => response.text()).then(data => {
        if (data==='SESSION TIMEOUT') {localStorage.setItem('vehicle_router_status', 'timedout'); window.location.reload();}
        else {
          setTimeout(() => {dispatch(clientStateOperations.refreshPlan());  }, 5000);
        }
      });
    });

    export const startClean: ThunkCommandFactory<void, StartCleanAction> = (
      () => (dispatch, getState, client) => {
        dispatch(actions.startClean());
        client.startSolve(getState().plan.sessionID, true, getState().plan.useWindows).then(response => response.text()).then(data => {
          if (data==='SESSION TIMEOUT') {localStorage.setItem('vehicle_router_status', 'timedout'); window.location.reload();}
          else {
            setTimeout(() => {dispatch(clientStateOperations.refreshPlan());  }, 5000);
          }
        });
      });

    export const stopSolve: ThunkCommandFactory<void, StopSolveAction | UpdateRouteAction> = (
      () => (dispatch, getState, client) => {
        dispatch(actions.stopSolve());
        client.stopSolve(getState().plan.sessionID).then((response) => response.json()).then(plan => {dispatch(updateRoute(plan)); if (!plan.isLoading) dispatch(clientStateOperations.finishLoading()); else setTimeout(() => {dispatch(clientStateOperations.refreshPlan())}, 5000) });
      });

      export const toggleUseWindows: ThunkCommandFactory<void, ToggleUseWindowsAction> = (
        () => (dispatch, getState, client) => {
          dispatch(actions.toggleUseWindows());
        });

        export const setSessionID: ThunkCommandFactory<string, SetSessionIDAction> = (
          (sessionID) => (dispatch, getState, client) => {
            dispatch(actions.setSessionID(sessionID));
          });