/*
 * Copyright 2018 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ActionFactory } from '../types';
import {
  ActionType,
  ClearRouteAction,
  DeleteLocationAction,
  MoveLocationsAction,
  AddDriverAction,
  EditDriversAction,
  UpdateDriversAction,
  Plan,
  StartSolveAction,
  StartCleanAction,
  StopSolveAction,
  SwapDriversAction,
  UpdateRouteAction,
  ToggleUseWindowsAction,
  SetSessionIDAction,
  DiscardProblemAction,
} from './types';

export const moveLocations: ActionFactory<string, MoveLocationsAction> = (locationids) => ({
  type: ActionType.MOVE_LOCATIONS,
  value: locationids,
});

export const addDriver: ActionFactory<string, AddDriverAction> = (driver) => ({
  type: ActionType.ADD_DRIVER,
  value: driver,
});

export const editDrivers: ActionFactory<string, EditDriversAction> = (edits) => ({
  type: ActionType.EDIT_DRIVERS,
  value: edits,
});

export const updateDrivers: ActionFactory<string, UpdateDriversAction> = (edits) => ({
  type: ActionType.UPDATE_DRIVERS,
  value: edits,
});

export const discardProblem: ActionFactory<number, DiscardProblemAction> = (problemID) => ({
  type: ActionType.DISCARD_PROBLEM,
  value: problemID,
});

export const deleteLocation: ActionFactory<number, DeleteLocationAction> = (id) => ({
  type: ActionType.DELETE_LOCATION,
  value: id,
});

export const clearRoute: ActionFactory<void, ClearRouteAction> = () => ({
  type: ActionType.CLEAR_SOLUTION,
});

export const swapDrivers: ActionFactory<string, SwapDriversAction> = (driverids) => ({
  type: ActionType.SWAP_DRIVERS,
  value: driverids,
});

export const startSolve: ActionFactory<void, StartSolveAction> = () => ({
  type: ActionType.START_SOLVE,
});

export const startClean: ActionFactory<void, StartCleanAction> = () => ({
  type: ActionType.START_CLEAN,
});

export const stopSolve: ActionFactory<void, StopSolveAction> = () => ({
  type: ActionType.STOP_SOLVE,
});

export const toggleUseWindows: ActionFactory<void, ToggleUseWindowsAction> = () => ({
  type: ActionType.TOGGLE_USE_WINDOWS,
});

export const updateRoute: ActionFactory<Plan, UpdateRouteAction> = (plan) => ({
  plan,
  type: ActionType.UPDATE_ROUTING_PLAN,
});

export const setSessionID: ActionFactory<string, SetSessionIDAction> = (sessionID) => ({
  sessionID,
  type: ActionType.SET_SESSIONID,
});
