import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { backendUrl } from 'common';
import { Button, Modal, Spinner } from "react-bootstrap";

export interface Props {
    handleClose: any;
    sessionID: string;
  }

export interface State {
    isPublishing: boolean;
    isValidating: boolean;
    isDone: boolean;
    text: string;
}

  class PublishDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.doPublish = this.doPublish.bind(this);
        this.state = {
            isPublishing: false,
            isValidating: true,
            isDone: false,
            text: " Validating that plan is up-to-date with schedule.  Please wait...",
        }
        this.doValidate();
    }

    doValidate() {
      fetch(`${backendUrl}/api/plan/test/${this.props.sessionID}`, {
        method: 'POST',
      }).then(response => response.text())
      .then(text => {
        this.setState({ text: text, isValidating: false });
        if (text.startsWith("A plan") || text.startsWith("Sorry")) this.setState({ isDone: true });
      }).catch(error => this.setState({ text: "Sorry, there was an error connecting to the database.", isValidating: false, isDone: true }));
    }

    doPublish() {
        if (this.state.isDone) {
            this.props.handleClose();
            return; 
        }
        this.setState({ isPublishing: true })

        fetch(`${backendUrl}/api/plan/publish/${this.props.sessionID}`, {
            method: 'POST',
          }).then(response => response.text())
          .then(text => {
            this.setState({ text: text, isPublishing: false, isDone: true })
          }).catch(error => this.setState({ text: "Sorry, there was an error connecting to the database.", isPublishing: false, isDone: true }));
    }
    
    render() {
        return(
        <Modal show={true} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
        <Modal.Title><FontAwesomeIcon icon={faUpload} /> Publish Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>{this.state.isValidating?<Spinner animation="border" size="sm" variant="primary" />:null}{this.state.text}</Modal.Body>
        <Modal.Footer><Button variant="secondary" onClick={this.props.handleClose}>
              Cancel
            </Button>

            <Button variant="primary" className={!this.state.isPublishing ? 'd-none' : ''}>
          <Spinner animation="border" size="sm" />&nbsp;Publish
          </Button>
          <Button variant="primary" onClick={this.doPublish} disabled={this.state.isValidating} className={this.state.isPublishing ? 'd-none' : ''}>
          {this.state.isDone? "OK" : "Publish"}
          </Button>

        </Modal.Footer>
        </Modal>
    );
    }
}


export default PublishDialog;