/*
 * Copyright 2018 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import L from 'leaflet';
import * as React from 'react';
import { useState } from 'react';
import { Map as LeafletMap, Polyline, TileLayer, ZoomControl } from 'react-leaflet';
import { UserViewport } from 'store/map/types';
import { Location, RouteWithTrack, Vehicle } from 'store/plan/types';
import { BoxSelect } from './BoxSelect';
import { Colors } from './Colors';
import LocationMarker from './LocationMarker';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface Props {
  selectedId: number;
  selectedLocations: number[];
  selectLocationHandler: (id: number) => void;
  selectLocationsHandler: (ids: number[]) => void;
  removeHandler: (id: number) => void;
  vehicles: Vehicle[];
  visits: Location[];
  //routes: Omit<RouteWithTrack, 'vehicle'>[];
  routes: RouteWithTrack[];
  hasRoutes: boolean;
  userViewport: UserViewport;
  updateViewport: (viewport: UserViewport) => void;
  unAssignedVisits: Location[]|null;
  setUnAssignedVisits: (visits: Location[]|null) => void;
}


const RouteMap: React.FC<Props> = ({
  userViewport,
  selectedId,
  selectedLocations,
  vehicles,
  visits,
  routes,
  hasRoutes,
  selectLocationHandler,
  selectLocationsHandler,
  updateViewport,
  unAssignedVisits,
  setUnAssignedVisits,
}) => {
  // TODO make TileLayer URL configurable
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  //
  const tileLayerUrl = window.Cypress ? 'test-mode-empty-url' : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

  const [multiStop, setMultiStop] = useState(new Map());  // used for displaying markers counting multiple clients at same stop

  // Change shift map box select to custom select function
  L.Map.mergeOptions({boxZoom: false, boxSelect: true});
  L.Map.addInitHook('addHandler', 'boxSelect', BoxSelect);

   function onBoxSelect(e:any) {
    const bounds = e.boxZoomBounds;
    const ids : number[] = [];

    //if (!hasRoutes) return;
    
    visits.forEach(function (visit, index) {
        if (bounds.contains(visit)) ids.push(visit.id); //console.log("Found: " + visit.name);
    });
    if (ids.length > 0) selectLocationsHandler(ids);
   }

   // Keep track of unAssigned Visits & multiple stops
  React.useEffect(() => {
    if (!hasRoutes) {
      setUnAssignedVisits(null);
      setMultiStop(new Map());
    } else {
      let allVisits = visits.slice(0);

      for (let i = 0; i < routes.length; i++) {
        allVisits = allVisits.filter( ( el ) => !routes[i].visits.map(v => v.id).includes( el.id ) );
      }
      setUnAssignedVisits(allVisits);

      // Create a map of every visit and the id of their driver
      const visitMap = new Map();
      for (const r of routes) {
        for (const v of r.visits) {
          visitMap.set(v.id, r.vehicle.id);
        }
      }

      const m = new Map();
      for (const l of visits) {
        let vs = visits.filter(v => v.lat === l.lat && v.lng === l.lng);
        if (vs.length > 1) {
          // This is a multistop.  Check to see if multiple drivers visiting this same stop
          let id : number = 0;
          for (const v of vs) {
            let i = visitMap.get(v.id);
            if (i == undefined) continue;
            if (id === 0) id = i; else if (id !== i) id = -1;
          }
          // if id == -1 at this point the visit has different drivers visiting the same stop
          if (id === -1) m.set(l.id, -1); else m.set(l.id, vs.length);
        }
      }
      setMultiStop(m);
    }
  }, [routes]);

  return (
    <LeafletMap
      viewport={userViewport}
      onViewportChanged={updateViewport}
      onboxzoomend={(e: Event) => onBoxSelect(e)}
      //onClick={clickHandler}
      // FIXME use height: 100%
      style={{ width: '100%', height: 'calc(100vh - 56px)' }}  // 56 is height of header
      //style={{ width: '100%', height: '100vh' }}
      zoomControl={false} // hide the default zoom control which is on top left
      //onmouseup={}
      
    >
      <TileLayer
        attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
        url={tileLayerUrl}
      />
      <ZoomControl position="topright" />
      {!hasRoutes ? 
      visits.map((location) => (
        // CLIENT Markers (Blue) that represent inital load of clients before route assignments
        <LocationMarker
          key={location.id}
          location={location}
          type={selectedLocations.includes(location.id) ? 'selected' : 'client'}
          route={0}
          isSelected={location.id === selectedId}
          selectLocationHandler={selectLocationHandler}
          driver={''}
        />
      )) : ''}
      {unAssignedVisits!= null && unAssignedVisits.length>0 ?
      unAssignedVisits.map((location) => (
        // Unassigned CLIENT Markers (white with black dot)
        <LocationMarker
          key={location.id}
          location={location}
          type={selectedLocations.includes(location.id) ? 'selected' : 'unassigned'}
          route={0}
          isSelected={location.id === selectedId}
          multiStop={multiStop.get(location.id)}
          selectLocationHandler={selectLocationHandler}
          driver={''}
        />
      )) : ''}
       {routes.map((route, index) => (
         // DRIVER Markers - will be green if the routes are not assigned yet
         <LocationMarker
         key={route.vehicle.id}
         location={route.vehicle}
         type={hasRoutes?'home':'driver'}
         route={route.vehicle.marker_color}
         isSelected={route.vehicle.id === selectedId}
         selectLocationHandler={function() { return; }}
         driver={''}
       />))}
       
       {routes.map((route, index) => (
         route.visits.map((visit, i) => (
           // CLIENT Markers that are colored
        <LocationMarker
        key={visit.id}
        location={visit}
        arrivalTime={route.arrivalTimes[i]}
        type={selectedLocations.includes(visit.id) ? 'selected' : 'route'}
        route={route.vehicle.marker_color}
        isSelected={visit.id === selectedId}
        selectLocationHandler={selectLocationHandler}
        multiStop={multiStop.get(visit.id)}
        driver={route.vehicle.name + " [Stop " + (i+1) + "]"}
      />))))}
       {routes.filter(r => r.track.length > 0).map((route, index) => (
        <Polyline
          // eslint-disable-next-line react/no-array-index-key
          key={index} // FIXME use unique id (not iteration index) (maybe route.vehicle.id)
          positions={route.track}
          fill={false}
          color={Colors.getColorHex(route.vehicle.marker_color)}
          smoothFactor={3}
          weight={9}
          opacity={0.6666}
        />
      ))}
    </LeafletMap>
  );
};

export default RouteMap;
